/* src/styles/App.css */
body {
  font-family: Arial, sans-serif;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.dog-container {
  text-align: center;
  margin-bottom: 20px;
}

.dog-container img {
  max-width: 100%;
  border-radius: 8px;
}

.competition-list {
  list-style-type: none;
  padding: 0;
}

.competition-list li {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}
